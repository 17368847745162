import React, { useState, useEffect } from 'react';
import { getTopN } from '../../api';
import { useSelector } from 'react-redux';
import { selectFilters, dashboardSlice, selectUserPortfolios } from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements, getSentimentColor, getUniqueValues } from '../../utils';
import BubbleChart from '../common/BubbleChart';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

function TickerChart() {
  const filter = useSelector(selectFilters);
  const userPortfolios = useSelector(selectUserPortfolios);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [plotData, ] = useState({});
  const [loading, setLoading] = useState(false);

  const preprocessing = (data) => {
    var sortedSentimentData2 = data.sort((a, b) => {
      if (a['sentiment'] === b['sentiment']) {
        return b['volume'] - a['volume'];
      }
      return b['sentiment'] - a['sentiment'];
    }).map((item) => {
      return item['ticker'];
    });
    var sortedReturnData2 = data.sort((a, b) => {
      if (a['return'] === b['return']) {
        return b['volume'] - a['volume'];
      }
      return b['return'] - a['return'];
    }).map((item) => {
      return item['ticker'];
    });
    
    var newData = data.map((item) => {
      // var x = findPercentileRank(sortedSentimentData, item['sentiment'], totalValues);
      // var y = findPercentileRank(sortedReturnData, item['return'], totalValues);
      var z = item['volume'];
      var color = getSentimentColor(item['sentiment']);
      var sentRank = sortedSentimentData2.indexOf(item['ticker']);
      // var sentRank = sortedSentimentData.indexOf(item['sentiment']);
      var retRank = sortedReturnData2.indexOf(item['ticker']);
      // var retRank = sortedReturnData.indexOf(item['return']);
      return {
        x: sentRank,
        y: retRank,
        z: z / 100,
        r: 0,
        ticker: item['ticker'],
        sentiment: item['sentiment'],
        volume: item['volume'],
        return: item['return'],
        name: item['company'],
        color: color,
        sentRank: sentRank,
        retRank: retRank
      }
    });
    return newData;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!userPortfolios || userPortfolios.length === 0 || !filter || !filter.portfolioId) {
        return;
      } else {
        try {
          let u = userPortfolios.filter(p => p.id === filter.portfolioId);
          console.log("filter: ", filter)
          console.log("userPortfolios: ", userPortfolios)
          console.log("u: ", u)
          if (u.length === 0) {
            u = userPortfolios
          }
          var tickers = getUniqueValues(u[0].symbols);
          const requestBody = {
            'start_date': filter.startDate,
            'end_date': filter.endDate,
            'stocks': tickers ? tickers : ['sp500'],
            'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
            sentiment_change: filter.mode,
            type: 'event',
            values: ['ALEX']
          }
          const response = await getTopN(requestBody);
          if (response.result != null) {
            var d = preprocessing(response.result['S1']);
            setData(d);
            dispatch(dashboardSlice.actions.setTopNData(response.result));
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    }

    fetchData();
  }, [filter, userPortfolios]);

  return (
    <div className='ticker-chart-container'>
      {loading ? (
        <div className='flex items-center justify-center w-full h-full'>
          <CircularProgress />
        </div>
      ) : (
        <BubbleChart chartData={data} plotData={plotData} />
      )}
    </div>
  );
}

export default TickerChart;