import React, {useState, useEffect, useReducer} from 'react';
import '../../assets/css/metadata-sidebar.css'
import {green, grey, red} from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import {getRowMetadata, annotationStorage, getAllEvents} from '../../api';
import Button from '@mui/material/Button';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {getShortenedString, getSourcesFromEntitlements, isCallEnt, isNewsEnt, isWebNewsEnt} from '../../utils';
import {useSelector} from 'react-redux';
import {selectFilters, selectUserId} from '../../reducers/dashboardSlice';
import Chip from '@mui/material/Chip';
import LoaderSidebar from './LoaderSidebar';

const MetadataSidebar = ({data, onClose, showEditBox, selectedText, onSaveClick}) => {
    const filter = useSelector(selectFilters);
    const userId = useSelector(selectUserId)
    const isCall = isCallEnt(filter.checkedEntitlement);
    const isNews = isNewsEnt(filter.checkedEntitlement);
    const isWebNews = isWebNewsEnt(filter.checkedEntitlement);
    const [metadata, setMetadata] = useState({});
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [editEventList, setEditEventList] = useState([]);
    const [allEventList, setAllEventList] = useState([]);
    const [editBoxState, setEditBoxState] = useState({
        text: selectedText, sentimentVal: 0, eventList: []
    });
    const [loading, setLoading] = useState(false);

    const preprocessing = (data) => {
        if (isCall) {
            data.storyId = data['transcript_id'] + '-' + data['section_id'];
            data.eventList = data.features.v4.events.split(',').map((event) => {
                const eventSplit = event.split(':');
                return {
                    eventName: eventSplit[0] === 'ALEX' ? 'OVERALL' : eventSplit[0],
                    sentimentVal: eventSplit[1]
                }
            });
            if (data.features.v4.user_phrases != null) {
                const userPhrase = JSON.parse(data.features.v4.user_phrases.replace(/'/g, '"'));
                data.userPhraseList = userPhrase.content
            } else {
                data.userPhraseList = [];
            }
        } else if (isWebNews) {
            data.storyId = data.id
            data.timestamp = data['post_time']
            data.eventList = Object.keys(data.sentiment_v4['equity.event']).map((key) => {
                return {
                    eventName: key.includes('ALEX') ? 'OVERALL' : key,
                    sentimentVal: data.sentiment_v4['equity.event'][key].split(':')[0]
                }
            });
            if (data.sentiment_v4.user_phrases != null && data.sentiment_v4.user_phrases !== '') {
                const userPhrase = JSON.parse(data.sentiment_v4.user_phrases.replace(/'/g, '"'));
                data.userPhraseList = userPhrase.content
            } else {
                data.userPhraseList = [];
            }
        } else if (isNews) {
            data.storyId = data.id;
            data.timestamp = data['received_time'];
            data.speaker = null;
            data.eventList = Object.keys(data.sentiment_v4['equity.event']).map((key) => {
                return {
                    eventName: key.includes('ALEX') ? 'OVERALL' : key,
                    sentimentVal: data.sentiment_v4['equity.event'][key].split(':')[0]
                }
            });
            if (data.sentiment_v4.user_phrases != null && data.sentiment_v4.user_phrases !== '') {
                const userPhrase = JSON.parse(data.sentiment_v4.user_phrases.replace(/'/g, '"'));
                data.userPhraseList = userPhrase.content
            } else {
                data.userPhraseList = [];
            }
        }
        return data;
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const requestBody = {
                    'sectionId': data.section,
                    'versionId': data.version,
                    'userId': userId,
                    'id': data.id,
                }
                const response = await getRowMetadata(requestBody, filter.checkedEntitlement);
                if (response.result != null) {
                    const d = preprocessing(response.result[0])
                    setMetadata(d);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        setTimeout(() => {
            fetchData();
        }, 500);
        setEditEventList([]);
        if (allEventList === null || allEventList.length === 0) {
            getAllEvents(getSourcesFromEntitlements(filter.checkedEntitlement)).then((response) => {
                if (response.result != null) {
                    setAllEventList(response.result);
                }
            })
        }
    }, [data, showEditBox]);

    const onMetadataChange = (newMetadata) => {
        setMetadata(newMetadata);
    }
    const getSubjectString = () => {
        let subjectString = '';
        metadata.eventList.forEach((event) => {
            var name = event.eventName === 'OVERALL' ? 'ALEX' : event.eventName;
            subjectString += name + ':' + event.sentimentVal + ':0:0:0:0,';
        });
        subjectString = subjectString.substring(0, subjectString.length - 1);
        return subjectString;
    }
    const getTextContent = () => {
        if (selectedText === null || selectedText.length === 0) {
            return [];
        }
        const content = [
            ...metadata.userPhraseList,
            {
                'start_offset': '1',
                'end_offset': '1',
                sentiment: editBoxState.sentimentVal.toString(),
                'keyword_value': selectedText,
                'events': editBoxState.eventList.map((event) => event).join(':').toUpperCase(),
            }
        ]
        return content;
    }

    const getTextSubjectString = (prevStr) => {
        if (selectedText === null || selectedText.length === 0) {
            return prevStr;
        }
        let textSubjectString = '';
        editBoxState.eventList.forEach((event) => {
            textSubjectString += event + ':' + editBoxState.sentimentVal + ':0:0:0:0,';
        });
        return prevStr + ',' + textSubjectString.substring(0, textSubjectString.length - 1);
    }

    const onSave = () => {
        async function pushData() {
            if (selectedText && editBoxState.eventList.length === 0) {
                alert('Please enter atleast one topic');
                return;
            }
            try {
                const counter = window.sessionStorage.getItem('annotationCounter');
                if (counter === null) {
                    window.sessionStorage.setItem('annotationCounter', '1');
                } else {
                    window.sessionStorage.setItem('annotationCounter', String(parseInt(counter) + 1));
                }
                const subjectString = getSubjectString();
                const requestBody = {
                    'documentid': metadata.storyId,
                    'keywords': {
                        'content': getTextContent()
                    },
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    'userid': userId,
                    'subjects': getTextSubjectString(subjectString)
                }
                const response = await annotationStorage(requestBody);
                if (response.result != null && response.result.status === 'SUCCESS') {
                    setSaveSuccess(true);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        pushData();
        onSaveClick();
    }

    const toggleSnackbar = () => {
        setSaveSuccess(false);
    }

    const SentimentSelect = ({value, eventName, onMetadataChange}) => {
        const [sentiment, setSentiment] = useState(value);
        const handleSentChange = (newVal) => {
            metadata.eventList.filter((event) => event.eventName === eventName)[0].sentimentVal = newVal;
            onMetadataChange(metadata);
            setSentiment(newVal);
        }
        return (
            <div className='col2' style={{marginTop: '-8px'}}>
                <Tooltip title="Positive">
                    <Checkbox
                        checked={sentiment === 1}
                        onChange={() => handleSentChange(1)}
                        inputProps={{'aria-label': 'controlled'}}
                        sx={{color: green[800], '&.Mui-checked': {color: green[600]}}}
                    />
                </Tooltip>
                <Tooltip title="Neutral">
                    <Checkbox
                        checked={sentiment === 0}
                        onChange={() => handleSentChange(0)}
                        inputProps={{'aria-label': 'controlled'}}
                        sx={{color: grey[800], '&.Mui-checked': {color: grey[600]}}}
                    />
                </Tooltip>
                <Tooltip title="Negative">
                    <Checkbox
                        checked={sentiment === -1}
                        onChange={() => handleSentChange(-1)}
                        inputProps={{'aria-label': 'controlled'}}
                        sx={{color: red[800], '&.Mui-checked': {color: red[600]}}}
                    />
                </Tooltip>
            </div>
        )
    }

    const TextSentimentSelect = ({sentiment}) => {
        const handleSentChange = (newVal) => {
            setEditBoxState({...editBoxState, sentimentVal: newVal});
        }
        return (
            <div className='col2'>
                <Checkbox
                    checked={sentiment === 1}
                    onChange={() => handleSentChange(1)}
                    inputProps={{'aria-label': 'controlled'}}
                    sx={{color: green[800], '&.Mui-checked': {color: green[600]}}}
                />
                <Checkbox
                    checked={sentiment === 0}
                    onChange={() => handleSentChange(0)}
                    inputProps={{'aria-label': 'controlled'}}
                    sx={{color: grey[800], '&.Mui-checked': {color: grey[600]}}}
                />
                <Checkbox
                    checked={sentiment === -1}
                    onChange={() => handleSentChange(-1)}
                    inputProps={{'aria-label': 'controlled'}}
                    sx={{color: red[800], '&.Mui-checked': {color: red[600]}}}
                />
            </div>
        )
    }

    const chipCss = (sentiment) => {
        let color = grey[300];
        if (sentiment === 1) color = green[200];
        else if (sentiment === -1) color = red[200];
        return {
            fontSize: '11px',
            height: '20px',
            lineHeight: '20px',
            marginBottom: '4px',
            backgroundColor: color,
        }
    }

    return (
        loading ? <LoaderSidebar/> :
            <div className='metadata-container'>
                <div className='modal-header'>
                    <div>
                        <div className=''>STORY ID:</div>
                        <div className='font-bold' style={{fontSize: '12px'}}>{metadata.storyId}</div>
                    </div>
                    <div className='modal-actions'>
                        <CloseIcon onClick={onClose} color='error' sx={{fontSize: '30px'}}/>
                    </div>
                </div>
                <div className='metadata-content'>
                    {metadata.timestamp && <div>
                        <div className=''>TIMESTAMP:</div>
                        <div className='mb-2 text-blue-900 metadata-value'>{metadata.timestamp}</div>
                    </div>}
                    {metadata.speaker && <div className='mb-4'>
                        <div className=''>SPEAKER:</div>
                        <div className=' text-blue-900 metadata-value'>{metadata.speaker.name}</div>
                        <div className=' text-blue-900 metadata-value'>{metadata.speaker.title}</div>
                        <div className=' text-blue-900 metadata-value mb-2'>{metadata.speaker.affiliation}</div>
                    </div>}
                    <div className='events-list-container'>
                        {metadata.eventList && metadata.eventList.map((event, index) => (
                            <div className='mb-2' key={index}>
                                <Chip label={event.eventName} sx={chipCss(event.sentimentVal)}/>
                                <div className='edit-event-row'>
                                    <SentimentSelect value={event.sentimentVal} eventName={event.eventName}
                                                     onMetadataChange={onMetadataChange}/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {metadata.userPhraseList && metadata.userPhraseList.length > 0 && (
                    <div className='editbox-container'>
                        <div className='editbox-label' style={{}}>
                            SAVED PHRASES
                        </div>
                        {metadata.userPhraseList.map((phrase, index) => (
                            <div key={index} className='phrase-container'>
                                <div className='editbox-text'>{getShortenedString(phrase.keyword_value)}</div>
                                {/* <Chip label={'OVERALL'} sx={{...chipCss, marginBottom: '4px', backgroundColor: green[500]}}/> */}
                                <div className='flex flex-wrap'>
                                    {phrase.events.split(':').map((event, index) => (
                                        <Chip label={event} sx={chipCss(phrase.sentiment)} key={index}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )
                }
                {showEditBox &&
                    <div className='editbox-container'>
                        <div className='editbox-label' style={{}}>
                            SELECTED TEXT
                        </div>
                        <div className='editbox-text'>{getShortenedString(selectedText)}</div>
                        <TextSentimentSelect sentiment={editBoxState.sentimentVal}/>
                        <Autocomplete
                            multiple
                            id="tags-filled"
                            options={allEventList}
                            freeSolo
                            renderTags={(value, getTagProps) => value.map((option, index) => (
                                <Chip variant="filled" label={option} {...getTagProps({index})} />))}
                            sx={{width: 200,}}
                            ListboxProps={{style: {maxHeight: 150, fontSize: '10px',}}}
                            renderInput={(params) => <TextField {...params}
                                                                sx={{'& label': {fontSize: '13px'}, width: 200,}}
                                                                label="ENTER TOPIC" variant='standard'/>}
                            onChange={(event, newValue) => setEditBoxState({...editBoxState, eventList: newValue})}
                        />
                        {editEventList && editEventList.length > 0 && (<div className='edit-event-list'>
                            {editEventList.map((event, index) => (
                                <div key={index} className='edit-event-name-chip'>{event}</div>
                            ))}
                        </div>)
                        }

                    </div>
                }
                <div className='ml-2'>
                    <Button variant="contained" startIcon={<SaveAltIcon/>} size="small"
                            sx={{marginRight: '8px'}} onClick={onSave}>
                        Save
                    </Button>
                    <Snackbar
                        open={saveSuccess}
                        autoHideDuration={5000}
                        onClose={toggleSnackbar}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                        <Alert onClose={toggleSnackbar} severity="success" sx={{width: '100%'}}>
                            Saved Successfully!
                        </Alert>
                    </Snackbar>
                </div>
            </div>
    );
};

export default MetadataSidebar;