import React, { useState } from 'react';
import { Tabs, Tab, Divider, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../assets/css/file-share/user-details.css';
import ClientFileTable from "./files/ClientFileTable";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Schedules from "./schedules/Schedules";
import DirectoryTree from "./directories/DirectoryTree";
import UploadComponent from "./uploads/UploadFile";


const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const StyledTab = styled(Tab)(({theme}) => ({
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '14px',
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.7)',
    '&.Mui-selected': {
        color: '#6a5acd',
    },
    minHeight: '48px',
    padding: '6px 12px',
}));

const IconWrapper = styled(Box)(({theme, selected}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    backgroundColor: selected ? '#e6e3ff' : '#e0e0e0',
    marginRight: theme.spacing(1),
    transition: 'background-color 0.3s',
}));


const AccountDetails = ({user}) => {
    const [activeTab, setActiveTab] = useState('files');

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const TabIcon = ({icon, selected}) => (
        <IconWrapper selected={selected}>
            {React.cloneElement(icon, {
                style: {
                    color: selected ? '#6a5acd' : 'rgba(0, 0, 0, 0.7)',
                    fontSize: '16px'
                }
            })}
        </IconWrapper>
    );

    return (
        <div className="user-details-container">
            <div className="user-details-navbar">
                <StyledTabs
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="user details tabs"
                >
                    <StyledTab
                        value="files"
                        label="Files"
                        icon={<TabIcon icon={<InsertDriveFileOutlinedIcon/>} selected={activeTab === 'files'}/>}
                        iconPosition="start"
                    />
                    <Divider orientation="vertical" flexItem/>
                    <StyledTab
                        value="schedules"
                        label="Schedules"
                        icon={<TabIcon icon={<ScheduleOutlinedIcon/>} selected={activeTab === 'schedules'}/>}
                        iconPosition="start"
                    />
                    <Divider orientation="vertical" flexItem/>
                    <StyledTab
                        value="directories"
                        label="Directories"
                        icon={<TabIcon icon={<FolderOutlinedIcon/>} selected={activeTab === 'directories'}/>}
                        iconPosition="start"
                    />
                    <Divider orientation="vertical" flexItem />
                    <StyledTab
                        value="upload"
                        label="Uploads"
                        icon={<TabIcon icon={<CloudUploadOutlinedIcon />} selected={activeTab === 'upload'} />}
                        iconPosition="start"
                    />
                </StyledTabs>
            </div>
            <div className="content-container">
                {activeTab === 'files' && <ClientFileTable accountID={user.account_id} user={user}/>}
                {activeTab === 'schedules' && <Schedules accountId={user.account_id}/>}
                {activeTab === 'directories' && <DirectoryTree account_id={user.account_id}/>}
                {activeTab === 'upload' && <UploadComponent account_id={user.account_id}/>}
            </div>
        </div>
    );
};

export default AccountDetails;