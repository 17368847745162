import React, {useState} from 'react';
import {
    Box,
    Button,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Typography,
    Paper,
    Snackbar
} from '@mui/material';
import {createTrialUser, createUserParallel, upsertUser} from '../../aePortalApi';
import {styled} from '@mui/material/styles';
import {getUserNameFromStorage} from "../../utils";
import CustomTextField from "./CustomTextField";

const StyledButton = styled(Button)({
    height: '40px',
    backgroundColor: '#6a5acd',
    '&:hover': {
        backgroundColor: '#5a4abf',
    },
});

const BannerTypography = styled(Typography)({
    backgroundColor: '#6a5acd', // Set the background color to match your main UI color
    color: 'white', // Set text color to white
    padding: '16px', // Add some padding for better spacing
});

const CreateUserForm = ({onClose, onUserCreated}) => {
    const [userType, setUserType] = useState('trial');
    const [existingUserType, setExistingUserType] = useState('parallel');
    const [formData, setFormData] = useState({
        email: '',
        account_id: '',
        company_name: '',
        name: '',
        poc_name: '',
        poc_email: '',
        password: ''
    });
    const [snackbar, setSnackbar] = useState({open: false, message: ''});

    const handleInputChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let response;
            if (userType === 'trial') {
                response = await createTrialUser(formData);
            } else if (existingUserType === 'parallel') {
                response = await createUserParallel(formData);
            } else {
                response = await upsertUser(formData);
            }
            if (response.status === 1) {
                setSnackbar({open: true, message: 'User created successfully!'});
                onUserCreated(response.response.user); // Pass the created user back to the parent component
                setTimeout(() => onClose(), 2000);
            } else {
                setSnackbar({open: true, message: 'Error creating user. Please try again.'});
            }
        } catch (error) {
            setSnackbar({open: true, message: 'An error occurred. Please try again.'});
        }
    };

    const adminUser = getUserNameFromStorage(localStorage.getItem('admin.username')).toLowerCase();

    console.log(adminUser)
    return (
        <>
            <BannerTypography variant="h5" gutterBottom>
                Create New User
            </BannerTypography>
            <Paper elevation={3} sx={{p: 3, maxWidth: 500, margin: 'auto'}}>
                <form onSubmit={handleSubmit}>
                    <FormControl component="fieldset" margin="normal">
                        <FormLabel component="legend">User Type</FormLabel>
                        <RadioGroup
                            row
                            name="user-type"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                        >
                            <FormControlLabel value="trial" control={<Radio
                                sx={{color: '#6a5acd', '&.Mui-checked': {color: '#6a5acd'}}}/>} label="Trial Client"/>
                          { adminUser === 'tapan01' && <FormControlLabel value="existing" control={<Radio
                                  sx={{color: '#6a5acd', '&.Mui-checked': {color: '#6a5acd'}}}/>}
                                                label="Existing Client"/> }
                        </RadioGroup>
                    </FormControl>

                    {userType === 'existing' && (
                        <FormControl component="fieldset" margin="normal">
                            <FormLabel component="legend">Existing Client Type</FormLabel>
                            <RadioGroup
                                row
                                name="existing-user-type"
                                value={existingUserType}
                                onChange={(e) => setExistingUserType(e.target.value)}
                            >
                                <FormControlLabel value="parallel" control={<Radio
                                    sx={{color: '#6a5acd', '&.Mui-checked': {color: '#6a5acd'}}}/>}
                                                  label="Parallel Account"/>
                                <FormControlLabel value="replace" control={<Radio
                                    sx={{color: '#6a5acd', '&.Mui-checked': {color: '#6a5acd'}}}/>}
                                                  label="Replace Account"/>
                            </RadioGroup>
                        </FormControl>
                    )}

                    <CustomTextField name={'email'} label={'Email'} value={formData.email} onChange={handleInputChange} required width={450}/>
                    <CustomTextField name={'company_name'} label={'Company Name'} value={formData.company_name} onChange={handleInputChange} required width={450}/>
                    <CustomTextField name={'name'} label={'Name'} value={formData.name} onChange={handleInputChange} required width={450}/>
                    <CustomTextField name={'poc_name'} label={'POC Name'} value={formData.poc_name} onChange={handleInputChange} required width={450}/>
                    <CustomTextField name={'poc_email'} label={'POC Email'} value={formData.poc_email} onChange={handleInputChange} required width={450}/>
                    <CustomTextField name={'account_id'} label={'Account ID'} value={formData.account_id} onChange={handleInputChange} required={userType !== 'trial'}/>
                    {userType === 'existing' && existingUserType === 'replace' && (
                        <CustomTextField name={'password'} label={'Password'} value={formData.password} onChange={handleInputChange} required/>
                    )}
                    <Box sx={{mt: 2}}>
                        <StyledButton type="submit" variant="contained">
                            Create User
                        </StyledButton>
                        <Button onClick={onClose} variant="outlined"
                                sx={{ml: 2, height: '40px', borderColor: '#6a5acd', color: '#6a5acd'}}>
                            Cancel
                        </Button>
                    </Box>
                </form>
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackbar({...snackbar, open: false})}
                    message={snackbar.message}
                />
            </Paper>
        </>
    );
};

export default CreateUserForm;