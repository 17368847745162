import React from 'react'
import EventChartFull from '../components/events-page/EventChartFull'
import DailyReports from '../components/events-page/DailyReports';
import TopTables from '../components/events-page/TopTables';

export default function EventsPage() {

    return (
        <div className="universe-container">
            <EventChartFull/>
            <DailyReports type={"event"}/>
            <TopTables/>
        </div>
    );
}
