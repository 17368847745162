import React, { useState, useEffect } from 'react';
import { getWordCount } from '../../api';
import {useDispatch, useSelector} from 'react-redux';
import {dashboardSlice, selectFilters, selectUserPortfolios} from '../../reducers/dashboardSlice';
import { getSourcesFromEntitlements, getUniqueValues, getNormalizedWeight } from '../../utils';
import WordCloud from '../common/WordCloud';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function WordChart() {
  const filter = useSelector(selectFilters);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userPortfolios = useSelector(selectUserPortfolios);
  const dispatch = useDispatch();

  const preprocessing = (data) => {

    data.sort(function (a, b) {
      return b['weight'] - a['weight'];
    });
    data = data.slice(0, 100);

    let keywordList = []

    data = data.map(item => {
      keywordList.push(item.text)
      return {
        name: item.text,
        weight: item.size
      };
    });

    dispatch(dashboardSlice.actions.setKeywordList(keywordList))
    return data;
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (!userPortfolios || userPortfolios.length === 0 || !filter || !filter.portfolioId) {
        return;
      } else {
        try {
          let u = userPortfolios.filter(p => p.id === filter.portfolioId);
          if (u.length === 0) {
            u = userPortfolios
          }
          var tickers = getUniqueValues(u[0].symbols);
          const requestBody = {
            'start_date': filter.startDate,
            'end_date': filter.endDate,
            'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
            sentiment_change: filter.mode,
            ticker: tickers,
          }
          const response = await getWordCount(requestBody);
          if (response.result != null) {
            var d = preprocessing(response.result);
            setData(d);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }

    }

    fetchData();
  }, [filter, userPortfolios]);

  const handleWordClick = (word) => {
    dispatch(dashboardSlice.actions.setFilterType('word'))
    dispatch(dashboardSlice.actions.addFilterTags(word))
    navigate('/words')
  }

  const handleClick = () => {
    navigate('/words');
  }

  return (
    <div className='word-chart-container' onClick={handleClick}>
      {loading ? (
        <div className='flex items-center justify-center w-full h-full'>
          <CircularProgress />
        </div>
      ) : (
        <WordCloud chartData={data} handlePointClick={handleWordClick} getNormalizedWeight={getNormalizedWeight}/>
      )}
    </div>
  );
}

export default WordChart;