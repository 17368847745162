import React, { useState, useEffect } from 'react';
import { adminLogin, getAuthToken } from "../../../aePortalApi";
import '../../../assets/css/file-share/auth.css';

const Auth = ({ setShowAuth }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [authState, setAuthState] = useState('idle'); // 'idle', 'loading', 'success', 'error'

    useEffect(() => {
        const checkStoredCredentials = async () => {
            const storedUsername = localStorage.getItem('admin.username');
            const storedPassword = localStorage.getItem('admin.password');

            if (storedUsername && storedPassword) {
                setAuthState('loading');
                try {
                    const authToken = await getAuthToken();
                    if (authToken) {
                        setAuthState('success');
                        setShowAuth(false);
                    } else {
                        // If authentication fails, clear stored credentials
                        localStorage.removeItem('admin.username');
                        localStorage.removeItem('admin.password');
                        setAuthState('idle');
                    }
                } catch (error) {
                    console.error("Authentication error:", error);
                    setAuthState('error');
                }
            }
        };

        checkStoredCredentials();
    }, [setShowAuth]);

    const handleLogin = async () => {
        setAuthState('loading');
        try {
            const encryptedUsername = btoa(username);
            const encryptedPassword = btoa(password);
            localStorage.setItem('admin.username', encryptedUsername);
            localStorage.setItem('admin.password', encryptedPassword);
            const response = await adminLogin(username.toUpperCase(), password);
            if (response !== {} && response['status'] === 1) {
                setAuthState('success');
                setShowAuth(false);
            } else {
                setAuthState('error');
            }
        } catch (error) {
            setAuthState('error');
        }
        // Reset state after 3 seconds
        setTimeout(() => setAuthState('idle'), 3000);
    }

    const getButtonText = () => {
        switch (authState) {
            case 'loading':
                return 'Authenticating...';
            case 'success':
                return 'Success!';
            case 'error':
                return 'Failed';
            default:
                return 'Authenticate';
        }
    };

    if (authState === 'loading' || authState === 'success') {
        return null; // Don't render anything while loading or after successful authentication
    }

    return (
        <div className="auth-container">
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button
                className={`auth-button ${authState}`}
                onClick={handleLogin}
                disabled={authState === 'loading'}
            >
                {getButtonText()}
            </button>
        </div>
    );
};

export default Auth;