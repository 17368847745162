import React from 'react';
import Button from '@mui/material/Button';
import FileUploadTwoToneIcon from '@mui/icons-material/FileUploadTwoTone';
import CircularProgress from '@mui/material/CircularProgress';
import { keyframes } from '@mui/system';

const uploadAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
`;

export const UploadButton = ({ handleUpload, disabled, uploading }) => (
    <Button
        fullWidth
        variant="contained"
        onClick={handleUpload}
        disabled={disabled || uploading}
        sx={{
            marginTop: 2,
            backgroundColor: '#6a5acd',
            '&:hover': { backgroundColor: '#5a4abf' },
            '&:disabled': { backgroundColor: '#9e9e9e' },
            position: 'relative',
            overflow: 'hidden',
        }}
    >
        {uploading ? (
            <>
                <CircularProgress
                    size={24}
                    sx={{
                        color: 'white',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
                <span style={{ visibility: 'hidden' }}>Upload</span>
            </>
        ) : (
            <>
                <FileUploadTwoToneIcon
                    sx={{
                        marginRight: 1,
                        animation: uploading ? `${uploadAnimation} 1s ease-in-out infinite` : 'none',
                    }}
                />
                Upload
            </>
        )}
    </Button>
);