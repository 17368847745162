import React, {useState, useEffect} from 'react';
import {
    Box,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Divider
} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {styled} from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {allTimezones, countryList, regionList} from '../../../constants';
import {createSchedule, deleteSchedule, getSchedules} from '../../../aePortalApi';
import {Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import '../../../assets/css/file-share/schedule.css';
import CustomSingleAutocomplete from "../CustomSingleAutoComplete";
import InfoTooltip from "./FileNameInfo";
import RecurrenceManager from "./RecurrenceManager";

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiInputBase-input': {
        fontSize: '12px',
    },
});

const Schedules = ({accountId}) => {
    const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [deletingButtons, setDeletingButtons] = React.useState({});


    const [entitlement, setEntitlement] = useState('equity');
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [folderName, setFolderName] = useState('V4-Daily');
    const [fileNameRegex, setFileNameRegex] = useState(entitlement + '.{end_date}.{endHH}{endMM}.csv');
    const [tz, setTz] = useState('UTC');
    const [checkedSource, setCheckedSource] = React.useState(new Set());
    const [refreshCounter, setRefreshCounter] = React.useState(0);
    const [createResponse, setCreateResponse] = useState(null);
    const [createApiStatus, setCreateApiStatus] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const [showResponse, setShowResponse] = useState(false);
    const [sentences, setSentences] = useState([]);

    const handleSentencesChange = (newSentences) => {
        setSentences(newSentences);
    };


    useEffect(() => {
        fetchSchedules();
    }, [accountId, refreshCounter]);

    const fetchSchedules = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await getSchedules(accountId);
            if (response.status === 1) {
                setSchedules(response.response.schedules);
            } else {
                setError('Failed to fetch schedules. Please try again.');
            }
        } catch (error) {
            console.error('Error fetching schedules:', error);
        }
        setIsLoading(false);
    };

    const handleCreateScheduleClick = async (event) => {
        setIsLoading(true);
        setIsSuccess(false);
        await handleCreateSchedule(event);
        setIsLoading(false);
        if (createApiStatus === 1) {
            setIsSuccess(true);
            setTimeout(() => setIsSuccess(false), 5000);
        }
        setShowResponse(true);
        setTimeout(() => setShowResponse(false), 5000);
        setTimeout(() => setCreateResponse(null), 5000);
    };

    const handleCreateSchedule = (event) => {
        event.preventDefault();
        const callCreateSchedule = async () => {
            const createScheduleRequest = {
                'account_id': accountId,
                'entitlement': entitlement,
                'sources': [...checkedSource],
                'countries': countries,
                'regions': regions,
                'crons': sentences,
                'timezone': tz,
                'folder_name': folderName,
                'filename_regex': fileNameRegex,
            }
            console.log('createScheduleRequest:', createScheduleRequest);
            const response = await createSchedule(createScheduleRequest);
            if (response.status === 1) {
                setRefreshCounter(prev => prev + 1);
                setCreateResponse(response.message)
                setCreateApiStatus(1)
            } else {
                console.error('Error creating schedule:', response.message);
                setCreateResponse(response.message)
                setCreateApiStatus(-1)
            }
        }
        callCreateSchedule();
    };

    const handleDeleteClick = (schedule) => {
        setSelectedSchedule(schedule);
        setOpenDeleteDialog(true);
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
        setSelectedSchedule(null);
    };

    const handleDeleteConfirm = async () => {
        // Implement delete schedule logic here
        setOpenDeleteDialog(false);
        const buttonId = `${selectedSchedule.file_id}-${selectedSchedule.subscription_id}`;
        setDeletingButtons({...deletingButtons, [buttonId]: 'loading'});
        try {
            const response = await deleteSchedule({
                "schedule_id": selectedSchedule.schedule_id,
                "subscription_id": selectedSchedule.subscription_id
            });
            if (response.status === 1) {
                setRefreshCounter(prev => prev + 1);
                setDeletingButtons({...deletingButtons, [buttonId]: 'success'});
            } else {
                setDeletingButtons({...deletingButtons, [buttonId]: 'error'});
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            setDeletingButtons({...deletingButtons, [buttonId]: 'error'});
        }
        setSelectedSchedule(null);
    };

    const sourceHandleChange = (source) => {
        let newCheckedSource = new Set(checkedSource);
        if (checkedSource.has(source)) {
            newCheckedSource.delete(source);
        } else {
            newCheckedSource.add(source);
        }
        setCheckedSource(new Set(newCheckedSource));
    };

    const columns = [
        { field: 'subscription_id', headerName: 'Entitlement', width: 200, headerClassName: 'bold-header' },
        { field: 'cron_expression', headerName: 'Cron', width: 80, headerClassName: 'bold-header' },
        { field: 'timezone', headerName: 'Timezone', width: 100, headerClassName: 'bold-header' },
        { field: 'timedelta', headerName: 'Delta', width: 70, headerClassName: 'bold-header' },
        { field: 'folder_name', headerName: 'Folder', width: 100, headerClassName: 'bold-header' },
        { field: 'latest_file_name', headerName: 'Latest File Name', width: 200, headerClassName: 'bold-header' },
        {
            field: 'delete_schedule',
            headerName: 'Actions',
            width: 120,
            headerClassName: 'bold-header',
            renderCell: (params) => {
                const buttonId = `${params.row.schedule_id}-${params.row.subscription_id}`;
                const buttonState = deletingButtons[buttonId] || 'default';
                return (
                    <Tooltip title={buttonState === 'success' ? 'File Deleted' : 'Delete File'}>
                    <span>
                        <Button
                            variant='contained'
                            color='error'
                            size='small'
                            onClick={() => handleDeleteClick(params.row)}
                            disabled={buttonState === 'loading'}
                            startIcon={
                                buttonState === 'loading' ? <CircularProgress size={20} color='inherit'/> :
                                    buttonState === 'success' ? <CheckCircleIcon/> : <DeleteIcon/>
                            }
                            sx={{
                                minWidth: '32px',
                                backgroundColor: buttonState === 'success' ? '#9e9e9e' : '#f44336',
                                '&:hover': {
                                    backgroundColor: buttonState === 'success' ? '#8e8e8e' : '#d32f2f',
                                },
                            }}
                        >
                            {buttonState === 'loading' ? 'Deleting' : ''}
                        </Button>
                    </span>
                    </Tooltip>
                );
            },
        }
    ];

    const handleRefresh = () => {
        setIsLoading(true);
        setRefreshCounter(prev => prev + 1);
    };

    return (
        <Box sx={{
            padding: '20px',
        }}>
            <form>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '24px'}}>
                    <FormControl sx={{flex: 1, maxWidth: 200, minWidth: 100}} required>
                        <InputLabel id='entitlement-label'>Entitlement</InputLabel>
                        <Select
                            labelId='entitlement-label'
                            value={entitlement}
                            label='Entitlement'
                            onChange={(e) => setEntitlement(e.target.value)}
                            sx={{height: '40px', fontSize: '12px'}}
                        >
                            <MenuItem value={'equity'}>Equity</MenuItem>
                            <MenuItem value={'commodity_v5'}>Commodity V5</MenuItem>
                            <MenuItem value={'economic_index'}>Economic Index</MenuItem>
                            <MenuItem value={'earnings_transcript'}>Earnings Transcript</MenuItem>
                            <MenuItem value={'ftri'}>FTRI</MenuItem>
                        </Select>
                    </FormControl>
                    {(entitlement === 'equity' || entitlement === 'earnings_transcript') && (<>
                        <CustomSingleAutocomplete multiple options={countryList} onChange={(event, newValue) => setCountries(newValue)} label='Countries' value={countries} width={300}/>
                        <CustomSingleAutocomplete options={regionList} onChange={(event, newValue) => setRegions(newValue)} label='Regions' value={regions} width={200}/>
                    </>)}
                    {entitlement !== 'earnings_transcript' && entitlement !== 'ftri' && (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginLeft: '5px'}}>
                            {['DN', 'BNZ', 'MDT', 'PR'].map((source, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checkedSource.has(source)}
                                            onChange={() => sourceHandleChange(source)}
                                            sx={{
                                                color: '#6a5acd',
                                                '&.Mui-checked': {
                                                    color: '#6a5acd',
                                                },
                                                padding: '4px'
                                            }}
                                        />
                                    }
                                    label={source}
                                />
                            ))}
                        </Box>
                    )}
                </Box>
                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '24px'}}>
                    <StyledTextField
                        label='Folder Name'
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        sx={{flex: 1, maxWidth: 300, minWidth: 100}}
                        required
                    />
                    <StyledTextField
                        label='File Name regex'
                        value={fileNameRegex}
                        onChange={(e) => setFileNameRegex(e.target.value)}
                        sx={{flex: 1, maxWidth: 300, minWidth: 100}}
                        required
                    />
                    <InfoTooltip/>
                    <CustomSingleAutocomplete required options={allTimezones} onChange={(event, newValue) => setTz(newValue)} label='Timezone' value={tz} width={200}/>
                </Box>
                {/*<RecurrenceSentence setCronExpr={setCronExpr} setTimeDelta={setTimeDelta}/>*/}
                <RecurrenceManager onSentencesChange={handleSentencesChange}/>
                <Button
                    type='submit'
                    variant='contained'
                    startIcon={isSuccess ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                    sx={{
                        backgroundColor: isSuccess ? '#4caf50' : '#6a5acd',
                        '&:hover': {
                            backgroundColor: isSuccess ? '#45a049' : '#5a4abf',
                        },
                        height: '40px'
                    }}
                    onClick={handleCreateScheduleClick}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                    ) : isSuccess ? (
                        'Created'
                    ) : (
                        'Create Schedule'
                    )}
                </Button>
                {showResponse && (
                    <Box sx={{
                        padding: '16px',
                        backgroundColor: createApiStatus === 1 ? '#e8f5e9' : '#ffebee',
                        color: createApiStatus === 1 ? '#2e7d32' : '#c62828',
                        borderRadius: '4px',
                        marginTop: '16px',
                        marginBottom: '24px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <InfoIcon sx={{marginRight: '8px'}}/>
                        {createResponse}
                    </Box>
                )}
            </form>
            <Divider sx={{marginBottom: '24px', marginTop: '32px'}}/>

            <div className='schedule-header'>
                <h5>Schedule Details</h5>
                <button onClick={handleRefresh} className='refresh-button'>
                    <RefreshIcon/>
                </button>
            </div>
            {isLoading ? (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                    <CircularProgress/>
                </Box>
            ) : error ? (
                <Box sx={{
                    padding: '16px',
                    backgroundColor: '#ffebee',
                    color: '#c62828',
                    borderRadius: '4px',
                    marginTop: '16px'
                }}>
                    {error}
                </Box>
            ) : (
                <>
                    {schedules && schedules.length > 0 && (
                        <div style={{ height: 400, width: '100%', fontSize: '12px' }}>
                            <DataGrid
                                rows={schedules}
                                columns={columns}
                                getRowId={(row) => row.subscription_id + row.cron_expression + row.timezone + row.timedelta}
                                initialState={{
                                    sorting: {
                                        sortModel: [{ field: 'account_id', sort: 'asc' }],
                                    },
                                }}
                                sx={{fontSize: '12px'}}
                            />
                        </div>
                    )}
                    {schedules.length === 0 && (
                        <div className='api-response-text error'>No schedules found for this account</div>
                    )}
                </>
            )}

            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteCancel}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{'Confirm Delete'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete this schedule?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Schedules;