import * as React from 'react';
import '../assets/css/accounts-page.css';


const UserResetPassword = () => {
    const [accountId, setAccountId] = React.useState("");
    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");


    const curlCommand = 'curl --location \'https://intel-dev.alexandriatechnology.com/cras/api/v1/admin/user/setpassword\' \\\n' +
        '--header \'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcyNjU5MTE3MiwianRpIjoiYzQ1OGEwNzYtYjA1Yy00NTBmLWI3ZjQtYzU5NDQwYzI5MTJiIiwidHlwZSI6ImFjY2VzcyIsInN1YiI6IlRBUEFOMDEiLCJuYmYiOjE3MjY1OTExNzIsImV4cCI6MTcyNjU5MjA3Mn0.nr3ADocgr3_CG2V9rY4dqV0gcnzGlwRYhWVCb2EDIRw\' \\\n' +
        '--header \'Content-Type: application/json\' \\\n' +
        '--data \'{\n' +
        '    "account_id": "TAPAN02",\n' +
        '    "old_password": "tapantest02",\n' +
        '    "new_password": "newtapantest02"\n' +
        '}\''

    return (
        <div className='page-container'>
            <div className="text-xl">Use This curl command to change password for a client</div>
            <div>{curlCommand}</div>
            {/*<TextField helperText=""*/}
            {/*           value={accountId}*/}
            {/*           onChange={(e) => setAccountId(e.target.value)}*/}
            {/*           label="Account ID"*/}
            {/*           sx={{width: 300, margin: '10px 10px 10px 0', height: 10}}/>*/}
            {/*<TextField helperText=""*/}
            {/*           value={oldPassword}*/}
            {/*           onChange={(e) => setOldPassword(e.target.value)}*/}
            {/*           label="Old Password"*/}
            {/*           sx={{width: 300, margin: '10px 10px 10px 0'}}/>*/}
            {/*<TextField helperText=""*/}
            {/*           value={newPassword}*/}
            {/*           onChange={(e) => setNewPassword(e.target.value)}*/}
            {/*           label="New Password"*/}
            {/*           sx={{width: 300, margin: '10px 10px 10px 0'}}/>*/}
            {/*<Button*/}
            {/*    variant="contained"*/}
            {/*    // onClick={() => setPasswordApi()}*/}
            {/*    sx={{mt: 1, mr: 1, height: 30}}*/}
            {/*>*/}
            {/*    Submit*/}
            {/*</Button>*/}
        </div>
    );
};

export default UserResetPassword;