import React from 'react';
import { MenuItem, Select, Typography, styled,} from '@mui/material';

const InlineSelect = styled(Select)(({theme}) => ({
    '&.MuiInputBase-root': {
        fontSize: '18px',
        color: 'inherit',
        '&:before, &:after': {
            display: 'none',
        },
    },
    '& .MuiSelect-select': {
        padding: '0',
        paddingRight: '0 !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    borderBottom: '1px solid #555555',
    borderRadius: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
        display: 'none', // Remove the down arrow icon
    },
    '& .MuiMenu-paper': {
        maxHeight: '240px',
    },
}));

const RecurrenceSentence = ({ index, sentence, updateSentence, onFrequencyChange}) => {
    const setHours = (newHours) => updateSentence(index, { hours: newHours });
    const setMinutes = (newMinutes) => updateSentence(index, { minutes: newMinutes });
    const setPastHours = (newPastHours) => updateSentence(index, { pastHours: newPastHours });
    const setPastMinutes = (newPastMinutes) => updateSentence(index, { pastMinutes: newPastMinutes });


    const handleFrequencyChange = (event) => {
        const newFrequency = event.target.value;
        if (newFrequency === 'hour') {
            console.log('hour');
            updateSentence(index, {frequency: 'hour', pastHours: 0, pastMinutes: 60});
        } else {
            updateSentence(index, {frequency: 'day', pastHours: 24, pastMinutes: 0});
        }
    };

    const handlePastHoursChange = (event) => {
        const newPastHours = Number(event.target.value);
        setPastHours(newPastHours);
        if (newPastHours === 24) {
            setPastMinutes(0);
        }
    };

    return (
        <Typography variant="body1" sx={{ fontSize: '14px', color: '#333' }}>
            Every
            <InlineSelect
                value={sentence.frequency}
                onChange={handleFrequencyChange}
                disabled={index > 0}
                sx={{ fontSize: '14px' }}
            >
                <MenuItem value="day">day</MenuItem>
                <MenuItem value="hour">hour</MenuItem>
            </InlineSelect>
            {sentence.frequency === 'day' ? (
                <>
                    at
                    <InlineSelect
                        value={sentence.hours}
                        onChange={(e) => setHours(Number(e.target.value))}
                        sx={{ fontSize: '14px' }}
                    >
                        {[...Array(24)].map((_, i) => (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        ))}
                    </InlineSelect>
                    :
                    <InlineSelect
                        value={sentence.minutes}
                        onChange={(e) => setMinutes(Number(e.target.value))}
                        sx={{ fontSize: '14px' }}
                    >
                        {[...Array(60)].map((_, i) => (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        ))}
                    </InlineSelect>
                    for past
                    <InlineSelect
                        value={sentence.pastHours}
                        onChange={handlePastHoursChange}
                        sx={{ fontSize: '14px' }}
                    >
                        {[...Array(24)].map((_, i) => (
                            <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                        ))}
                    </InlineSelect>
                    hours
                    {sentence.pastHours < 24 && (
                        <>
                            {' '}
                            and
                            <InlineSelect
                                value={sentence.pastMinutes}
                                onChange={(e) => setPastMinutes(Number(e.target.value))}
                                sx={{ fontSize: '14px' }}
                            >
                                {[...Array(60)].map((_, i) => (
                                    <MenuItem key={i} value={i}>{i}</MenuItem>
                                ))}
                            </InlineSelect>
                            minutes
                        </>
                    )}
                    .
                </>
            ) : (
                <>
                    at
                    <InlineSelect
                        value={sentence.minutes}
                        onChange={(e) => setMinutes(e.target.value)}
                        sx={{ fontSize: '14px' }}
                    >
                        {[...Array(60)].map((_, i) => (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        ))}
                    </InlineSelect>
                    minutes for past
                    <InlineSelect
                        value={sentence.pastMinutes}
                        onChange={(e) => setPastMinutes(Number(e.target.value))}
                        sx={{ fontSize: '14px' }}
                    >
                        {[15, 30, 45, 60].map((value) => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        ))}
                    </InlineSelect>
                    minutes.
                </>
            )}
        </Typography>
    );
};

export default RecurrenceSentence;