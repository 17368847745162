import React from 'react';
import '../assets/css/file-share/main-page.css'
import styles from '../assets/css/file-share/CustomScrollbar.module.css'
import Auth from "../components/file-share-page-2/login/AuthInputs";
import Users from "../components/file-share-page-2/Users";
import Banner from "../components/file-share-page-2/Banner";

const FileSharePage2 = () => {
    const [showAuth, setShowAuth] = React.useState(true);

    return (
        <div className={styles.customScrollbar}>
            <div className="main-page">
                <Banner/>
                <main className="content">
                    {showAuth && <Auth setShowAuth={setShowAuth}/>}
                    {!showAuth && <Users/>}
                </main>
            </div>
        </div>
    );
};

export default FileSharePage2;