import React, {useEffect, useState} from 'react';
import '../../../assets/css/file-share/directory-tree.css';
import {getDirectoryTree} from "../../../aePortalApi";

const TreeNode = ({name, value}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const isFolder = value !== null && typeof value === 'object';

    const toggleExpand = () => {
        if (isFolder) {
            setIsExpanded(!isExpanded);
        }
    };

    return (
        <div className="tree-node">
            <div className={`node-content ${isFolder ? 'folder' : 'file'}`} onClick={toggleExpand}>
                {isFolder && (
                    <span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>
            {isExpanded ? '▼' : '▶'}
          </span>
                )}
                <span className={`node-icon ${isFolder ? 'folder' : 'file'}`}></span>
                <span className="node-name">{name}</span>
            </div>
            {isExpanded && isFolder && (
                <div className="node-children">
                    {value && Object.entries(value).map(([childName, childValue]) => (
                        <TreeNode key={childName} name={childName} value={childValue}/>
                    ))}
                </div>
            )}
        </div>
    );
};

const DirectoryTree = ({account_id}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const response = await getDirectoryTree({account_id: account_id});
            if (response.status === 1) {
                const tree = JSON.parse(response.response.tree)
                console.log(tree)
                if (Object.keys(tree).length === 0) {
                    setData({});
                } else {
                    setData(tree);
                }
            } else {
                setData({})
            }
        };
        fetchData();
    }, [account_id]);

    // console.log(data)
    return (
        <div className="folder-tree-container">
            <h5>Directory Tree</h5>
            <div className="folder-tree">
                {Object.entries(data).map(([name, value]) => (
                    <TreeNode key={name} name={name} value={value} />
                ))}
            </div>
        </div>
    );
};

export default DirectoryTree;