import React, {useEffect} from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import AppHeader from './components/common/AppHeader';
import UniversePage from './pages/UniversePage';
import CompanyPage from './pages/CompanyPage';
import EventsPage from './pages/EventsPage';
import WordsPage from './pages/WordsPage';
import Sidebar from './components/common/Sidebar'
import {useSelector} from 'react-redux';
import {selectIsCollapsed} from './reducers/dashboardSlice';
import FilterBar from "./components/common/FilterContainer";
import SignInPage from "./components/common/SignInPage";
import {checkUserSession} from "./api";
import UserListPage from "./pages/UserListPage";
import UserResetPassword from "./pages/UserResetPassword";
import FileSharePage2 from "./pages/FileSharePage2";


export default function App() {
    const collapsed = useSelector(selectIsCollapsed)
    const signInPage = window.location.href.includes('sign_in')
    const isAdminPage = window.location.href.includes('admin')

    useEffect(() => {
        if (!checkUserSession) {
            window.location.href = '/sign_in'
        }
    }, [])

    return signInPage ? <SignInPage/> : (
        <div className='flex'>
            {!isAdminPage && <Sidebar/>}
            <div className={`rigth-container ${collapsed ? 'collapsed' : ''}`}>
                <Router>
                    {!isAdminPage && <>
                        <AppHeader/>
                        <FilterBar/></>
                    }
                    <Routes>
                        <Route exact path='/' element={<UniversePage/>}></Route>
                        <Route exact path='/events' element={<EventsPage/>}></Route>
                        <Route exact path='/words' element={<WordsPage/>}></Route>
                        <Route exact path='/ticker/:tickerName' element={<CompanyPage/>}></Route>
                        <Route exact path='/admin/file_share' element={<FileSharePage2/>}></Route>
                        <Route exact path='/admin/file_share/users/all' element={<UserListPage/>}></Route>
                        <Route exact path='/admin/file_share/users/reset_password' element={<UserResetPassword/>}></Route>
                    </Routes>
                </Router>
            </div>
        </div>
    );
}
