import React, {useEffect, useState} from 'react';
import {Box, Typography, TextField, Button, Paper} from '@mui/material';
import {styled} from '@mui/material/styles';
import {RichTreeView} from '@mui/x-tree-view/RichTreeView';
import {aePortalBaseUrl, getDirectoryPaths} from "../../../aePortalApi";
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import FolderTwoToneIcon from '@mui/icons-material/FolderTwoTone';
import {useTreeItem2} from '@mui/x-tree-view/useTreeItem2';
import {TreeItem2Provider} from '@mui/x-tree-view/TreeItem2Provider';
import {treeItemClasses} from '@mui/x-tree-view/TreeItem';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import {useTreeViewApiRef} from '@mui/x-tree-view/hooks';
import {
    TreeItem2Content,
    TreeItem2Label,
    TreeItem2Root,
    TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2';
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {UploadButton} from "./UploadButton";

const UploadBox = styled(Box)(({theme}) => ({
    border: `2px dashed black`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledTreeItemRoot = styled(TreeItem2Root)(({theme}) => ({
    color: theme.palette.grey[400],
    // position: 'relative',
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: theme.spacing(3.5),
    },
    ...theme.applyStyles('light', {
        color: theme.palette.grey[800],
    }),
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({theme}) => ({
    flexDirection: 'row-reverse',
    borderRadius: theme.spacing(0.7),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
    [`&.Mui-expanded `]: {
        '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
            color: theme.palette.primary.dark,
            ...theme.applyStyles('light', {
                color: theme.palette.primary.main,
            }),
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: '16px',
            top: '44px',
            height: 'calc(100% - 48px)',
            width: '1.5px',
            backgroundColor: theme.palette.grey[700],
            ...theme.applyStyles('light', {
                backgroundColor: theme.palette.grey[300],
            }),
        },
    },
    // '&:hover': {
    //     backgroundColor: alpha(theme.palette.primary.main, 0.1),
    //     color: 'white',
    //     ...theme.applyStyles('light', {
    //         color: theme.palette.primary.main,
    //     }),
    // },
    // [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    //     // backgroundColor: '#c93737',
    //     // color: '#3b3b3b',
    //     ...theme.applyStyles('light', {
    //         backgroundColor: theme.palette.primary.main,
    //     }),
    // },
}));

const StyledTreeItemLabelText = styled(Typography)({
    color: 'inherit',
    fontSize: '12px'
    // fontFamily: 'General Sans',
    // fontWeight: 500,
});

// function DotIcon() {
//     return (
//         <Box
//             sx={{
//                 width: 6,
//                 height: 6,
//                 borderRadius: '70%',
//                 bgcolor: 'warning.main',
//                 display: 'inline-block',
//                 verticalAlign: 'middle',
//                 zIndex: 1,
//                 mx: 1,
//             }}
//         />
//     );
// }

function CustomLabel({icon: Icon, expandable, children, ...other}) {
    return (
        <TreeItem2Label
            {...other}
            sx={{
                display: 'flex',
                alignItems: 'bottom',
            }}
        >
            {expandable ? <KeyboardArrowDownIcon sx={{fontSize: 'small'}}/> : <KeyboardArrowRightIcon sx={{fontSize: 'small'}}/>}
            {Icon && (
                <Box
                    component={Icon}
                    className="labelIcon"
                    color="inherit"
                    sx={{mr: '3px', fontSize: '15px'}}
                />
            )}

            <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>

        </TreeItem2Label>
    );
}

const getIconFromFileType = (fileType) => {
    switch (fileType) {
        case 'image':
            return ImageIcon;
        case 'pdf':
            return PictureAsPdfIcon;
        case 'doc':
            return InsertDriveFileTwoToneIcon;
        case 'video':
            return VideoCameraBackIcon;
        case 'folder':
            return FolderTwoToneIcon;
        case 'trash':
            return DeleteIcon;
        default:
            return ArticleIcon;
    }
};

const isExpandable = (reactChildren) => {
    if (Array.isArray(reactChildren)) {
        return reactChildren.length > 0 && reactChildren.some(isExpandable);
    }
    return Boolean(reactChildren);
};

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const {id, itemId, label, disabled, children, ...other} = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getCheckboxProps,
        getLabelProps,
        getGroupTransitionProps,
        getDragAndDropOverlayProps,
        status,
        publicAPI,
    } = useTreeItem2({id, itemId, children, label, disabled, rootRef: ref});

    const item = publicAPI.getItem(itemId);
    const expandable = isExpandable(children);
    const icon = getIconFromFileType(item.fileType);

    return (
        <TreeItem2Provider itemId={itemId}>
            <StyledTreeItemRoot {...getRootProps(other)}>
                {/*<TreeItem2IconContainer {...getIconContainerProps()}>*/}
                {/*    <TreeItem2Icon status={status} />*/}
                {/*</TreeItem2IconContainer>*/}
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-selected': status.selected,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    {/*<TreeItem2Checkbox {...getCheckboxProps()} />*/}
                    <CustomLabel
                        {...getLabelProps({icon, expandable: expandable && status.expanded})}
                    />
                    {/*<TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />*/}
                </CustomTreeItemContent>
                {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
            </StyledTreeItemRoot>
        </TreeItem2Provider>
    );
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#e0e0e0',
        },
        '&:hover fieldset': {
            borderColor: '#6a5acd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6a5acd',
        },
        fontSize: '12px',
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
        '&.Mui-focused': {
            color: '#6a5acd',
        },
    },
    marginBottom: '16px',
});

const UploadComponent = ({account_id}) => {
    const [selectedDirectory, setSelectedDirectory] = useState('');
    const [file, setFile] = useState(null);
    const [directoryTree, setDirectoryTree] = useState([]);
    const [uploading, setUploading] = useState(false);

    const apiRef = useTreeViewApiRef();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDirectoryPaths(account_id);
                if (response.status === 1) {
                    setDirectoryTree(response.response.paths);
                } else {
                    setDirectoryTree([]);
                }
            } catch (error) {
                console.error("Error fetching directory tree:", error);
                setDirectoryTree([]);
            }
        };
        fetchData();
    }, [account_id]);

    const getNode = (nodeId) => {
        const searchNode = (node, nodeId) => {
            if (node.id === nodeId) {
                return node;
            }
            if (node.children) {
                for (let child of node.children) {
                    const foundNode = searchNode(child, nodeId);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        for (let node of directoryTree) {
            const foundNode = searchNode(node, nodeId);
            if (foundNode) {
                return foundNode;
            }
        }
    }

    const handleDirectorySelect = (event, nodeId) => {
        const node = getNode(nodeId)
        if (node.fileType === 'folder') {
            setSelectedDirectory(node.id);
        }
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
    };

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const handleUpload = async () => {
        if (file && selectedDirectory) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('directory', selectedDirectory);
            formData.append('account_id', account_id);

            try {
                const response = await fetch(`${aePortalBaseUrl}/file/upload`, {
                    method: 'POST',
                    body: formData
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log(`File uploaded successfully: ${result.message}`);
                    setFile(null);
                    setSelectedDirectory('');
                    // Optionally, update the UI or refresh the file list
                } else {
                    console.error('Upload failed');
                    alert('File upload failed. Please try again.');
                }
                setUploading(false);
            } catch (error) {
                console.error('Error during upload:', error);
                alert('An error occurred during upload. Please try again.');
                setUploading(false);
            }
        } else {
            alert('Please select a directory and a file to upload.');
        }
    };


    return (
        <Box sx={{maxWidth: 600, margin: 'auto', padding: 2}}>
            <Typography gutterBottom sx={{color: '#6a5acd', alignItems: 'center', justifyItems: 'center'}}>
                Select Directory
            </Typography>

            <Paper elevation={3} sx={{padding: 2, marginBottom: 2}}>
                <StyledTextField
                    fullWidth
                    variant="outlined"
                    value={selectedDirectory}
                    placeholder="Selected Directory"
                    InputProps={{readOnly: true}}
                    sx={{marginBottom: 2}}
                />
                <div style={{height: 200, flexGrow: 1, overflowY: 'auto', overflowX: 'auto'}}>
                    <RichTreeView
                        items={directoryTree}
                        apiRef={apiRef}
                        defaultExpandedItems={['1', '1.1']}
                        sx={{height: 'fit-content', flexGrow: 1, overflowY: 'auto'}}
                        slots={{item: CustomTreeItem}}
                        experimentalFeatures={{indentationAtItemLevel: true, itemsReordering: true}}
                        itemsReordering
                        canMoveItemToNewPosition={(params) => {
                            return (
                                params.newPosition.parentId === null ||
                                ['folder', 'trash'].includes(
                                    apiRef.current.getItem(params.newPosition.parentId).fileType,
                                )
                            );
                        }}
                        onSelectedItemsChange={handleDirectorySelect}
                    />
                </div>
            </Paper>

            <UploadBox
                onDrop={handleFileDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <input
                    type="file"
                    onChange={handleFileSelect}
                    style={{display: 'none'}}
                    id="file-input"
                />
                <label htmlFor="file-input">
                    <Button
                        variant="contained"
                        component="span"
                        sx={{backgroundColor: '#9e9e9e', '&:hover': {backgroundColor: '#5a4abf'}}}
                        startIcon={<InsertDriveFileTwoToneIcon />}
                    >
                        Select File
                    </Button>
                </label>
                <Typography variant="body1" sx={{marginTop: 2, fontSize: '14px'}}>
                    {file ? `Selected file: ${file.name}` : 'Drag and drop a file here or click to select'}
                </Typography>
            </UploadBox>

            <UploadButton
                handleUpload={handleUpload}
                disabled={!file || !selectedDirectory}
                uploading={uploading}
            />
        </Box>
    );
};

export default UploadComponent;