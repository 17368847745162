import * as React from 'react';
import '../../../assets/css/accounts-page.css';
import { getFilesByAccount, sendFileEmail, deleteFile, updatePassword } from '../../../aePortalApi';
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import CreateFileForm from "./CreateFileForm";
import { Tooltip, IconButton, TextField } from '@mui/material';
import {styled} from "@mui/material/styles";

const loaderStyle = `
  .user-info-and-form-container {
    display: flex;
  }
  
  .user-info-container {
    flex: 1;
    padding-right: 20px;
  }
  
  .create-file-form-container {
    flex: 1;
    padding-left: 20px;
  }
  
  /* Adjust the table styles for better readability */
  .MuiTableCell-root {
    padding: 8px 16px;
  }
  
  .MuiTableCell-head {
    font-weight: bold;
  }
  .loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #6a5acd;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .resend-button {
    min-width: 120px;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .resend-button.default {
    background-color: #6a5acd;
    color: white;
  }
  .resend-button.loading {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
  .resend-button.success {
    background-color: #4caf50;
    color: white;
  }
  .delete-button {
    min-width: 120px;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .delete-button.default {
    background-color: #f44336;
    color: white;
  }
  .delete-button.loading {
    background-color: #9e9e9e;
    cursor: not-allowed;
  }
  .delete-button.success {
    background-color: #9e9e9e;
    color: white;
  }
  .api-files-header {
    display: flex;
    align-items: center;
  }
  
  .api-files-header h2 {
      font-size: 1.2rem;
      font-weight: bold;
      color: #333;
  }
  
  .refresh-button {
      background: none;
      border: none;
      cursor: pointer;
      color: #6a5acd;
      display: flex;
      align-items: center;
      transition: transform 0.3s ease;
  }
  
  .refresh-button:hover {
      transform: rotate(180deg);
  }
  
  .refresh-button svg {
      font-size: 24px;
  }
`;

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '30px',
        width: '150px'
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#e0e0e0',
        },
        '&:hover fieldset': {
            borderColor: '#6a5acd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6a5acd',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
        '&.Mui-focused': {
            color: '#6a5acd',
        }
    },
});

const ClientFileTable = ({accountID, user}) => {

    const [files, setFiles] = useState([]);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingButtons, setLoadingButtons] = useState({});
    const [deletingButtons, setDeletingButtons] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [editingPassword, setEditingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    useEffect(() => {
        const handleAccoundIdSearch = async () => {
            setIsLoading(true);
            try {
                const response = await getFilesByAccount(accountID);
                if (response['status'] === 1) {
                    const response_files = response['response']['files'];
                    if (response_files.length === 0) {
                        setShowEmptyError(true);
                        setFiles([]);
                    } else {
                        setFiles(response['response']['files']);
                        setShowEmptyError(false);
                    }
                } else if (response['status'] === -1) {
                    setShowEmptyError(true);
                    setFiles([]);
                }
            } catch (error) {
                console.error("Error fetching files:", error);
                setShowEmptyError(true);
                setFiles([]);
            } finally {
                setIsLoading(false);
            }
        };
        handleAccoundIdSearch();
    }, [accountID, refreshCounter]);

    const handleEmailSendClick = async (file) => {
        console.log('calling email send api 1')
        const buttonId = `${file.file_id}-${file.subscription_id}`;
        setLoadingButtons(prev => ({...prev, [buttonId]: 'loading'}));
        try {
            console.log('calling email send api')
            const response = await sendFileEmail(file['id']);
            console.log('calling email send api 2')
            if (response['status'] === 1) {
                setLoadingButtons(prev => ({...prev, [buttonId]: 'success'}));
                setTimeout(() => {
                    setLoadingButtons(prev => ({...prev, [buttonId]: 'default'}));
                }, 3000);
            } else {
                setLoadingButtons(prev => ({...prev, [buttonId]: 'default'}));
                // alert('Failed to send email');
            }
        } catch (error) {
            console.error("Error sending email:", error);
            setLoadingButtons(prev => ({...prev, [buttonId]: 'default'}));
            alert('Error sending email');
        }
    };

    const handleDeleteClick = (file) => {
        setFileToDelete(file);
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = async () => {
        setOpenDeleteDialog(false);
        if (fileToDelete) {
            console.log(fileToDelete)
            const buttonId = `${fileToDelete.id}-${fileToDelete.subscription_id}`;
            setDeletingButtons(prev => ({...prev, [buttonId]: 'loading'}));
            try {
                const response = await deleteFile(fileToDelete.id);
                if (response.status === 1) {
                    setDeletingButtons(prev => ({...prev, [buttonId]: 'success'}));
                } else {
                    setDeletingButtons(prev => ({...prev, [buttonId]: 'default'}));
                    // alert('Failed to delete file');
                }
                // Refresh the file list
                setRefreshCounter(prev => prev + 1);
            } catch (error) {
                console.error("Error deleting file:", error);
                setDeletingButtons(prev => ({...prev, [buttonId]: 'default'}));
                alert('Error deleting file');
            }
        }
    };

    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
        setFileToDelete(null);
    };

    const handleRefresh = () => {
        setIsLoading(true);
        setRefreshCounter(prev => prev + 1);
    };


    const columns = [
        {field: 'id', headerName: 'Id', width: 30},
        {field: 'subscription_id', headerName: 'Service', width: 260},
        {field: 'status', headerName: 'Status', width: 120},
        {field: 'start_time', headerName: 'Start Time', width: 170},
        {field: 'end_time', headerName: 'End Time', width: 170},
        {field: 'download_time', headerName: 'Download Time', width: 170},
        {
            field: 'resend_link',
            headerName: '',
            width: 60,
            renderCell: (params) => {
                const buttonId = `${params.row.id}-${params.row.subscription_id}`;
                const buttonState = loadingButtons[buttonId] || 'default';
                return (
                    <Tooltip title={buttonState === 'success' ? 'Email Sent' : 'Send Email'}>
                    <span>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleEmailSendClick(params.row)}
                            disabled={buttonState === 'loading'}
                            startIcon={
                                buttonState === 'loading' ? <CircularProgress size={20} color="inherit" /> :
                                    buttonState === 'success' ? <CheckCircleIcon /> : <EmailIcon />
                            }
                            sx={{
                                minWidth: '32px',
                                backgroundColor: buttonState === 'success' ? '#4caf50' : '#6a5acd',
                                '&:hover': {
                                    backgroundColor: buttonState === 'success' ? '#45a049' : '#5a4abf',
                                },
                                '& .MuiButton-startIcon': {
                                    marginRight: buttonState === 'success' || buttonState === 'loading' ? '8px' : '0',
                                },
                            }}
                        >
                            {buttonState === 'loading' ? 'Sending' : buttonState === 'success' ? 'Sent' : ''}
                        </Button>
                    </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'delete_file',
            headerName: '',
            width: 100,
            renderCell: (params) => {
                const buttonId = `${params.row.file_id}-${params.row.subscription_id}`;
                const buttonState = deletingButtons[buttonId] || 'default';
                return (
                    <Tooltip title={buttonState === 'success' ? 'File Deleted' : 'Delete File'}>
                    <span>
                        <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleDeleteClick(params.row)}
                            disabled={buttonState === 'loading'}
                            startIcon={
                                buttonState === 'loading' ? <CircularProgress size={20} color="inherit" /> :
                                    buttonState === 'success' ? <CheckCircleIcon /> : <DeleteIcon />
                            }
                            sx={{
                                minWidth: '32px',
                                backgroundColor: buttonState === 'success' ? '#9e9e9e' : '#f44336',
                                '&:hover': {
                                    backgroundColor: buttonState === 'success' ? '#8e8e8e' : '#d32f2f',
                                },
                                '& .MuiButton-startIcon': {
                                    marginRight: buttonState === 'success' || buttonState === 'loading' ? '8px' : '0',
                                },
                            }}
                        >
                            {buttonState === 'loading' ? 'Deleting' :
                                buttonState === 'success' ? 'Deleted' : ''}
                        </Button>
                    </span>
                    </Tooltip>
                );
            },
        },
    ];

    const handleEditPassword = () => {
        setEditingPassword(true);
        setNewPassword(user.password);
    };

    const handleSavePassword = async () => {
        if (newPassword && newPassword !== '' && newPassword.length >= 6 && newPassword !== user.password) {
            try {
                const response = await updatePassword(accountID, newPassword);
                if (response.status === 1) {
                    user.password = newPassword;
                    setEditingPassword(false);
                } else {
                    alert('Failed to update password');
                }
            } catch (error) {
                console.error("Error updating password:", error);
                alert('Error updating password');
            }
        } else {
            alert('Failed to update password. Password must be at least 6 characters long and different from the current password');
            setEditingPassword(false)
        }
    }

    return (
        <div className='pr-4'>
            <style>{loaderStyle}</style>
            <div className="user-info-and-form-container">
                <div className="user-info-container">
                    {user && user['email'] !== undefined && (
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="user info table">
                                <TableBody>
                                    {[
                                        {label: 'Account ID', value: user['account_id']},
                                        {
                                            label: 'Password',
                                            value: editingPassword ? (
                                                <StyledTextField
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    size="small"
                                                />
                                            ) : user['password'],
                                            action: editingPassword ? (
                                                <IconButton onClick={handleSavePassword} size="small">
                                                    <CheckCircleIcon sx={{width: '20px', height: '20px', color: 'green'}}/>
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={handleEditPassword} size="small">
                                                    <EditIcon sx={{width: '14px', height: '14px'}}/>
                                                </IconButton>
                                            )
                                        },
                                        {label: 'Email', value: user['email']},
                                        {label: 'Name', value: user['name']},
                                        {label: 'Company', value: user['company_name']},
                                        {label: 'POC Name', value: user['poc_name']},
                                        {label: 'POC Email', value: user['poc_email']}
                                    ].map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {row.label}
                                            </TableCell>
                                            <TableCell>
                                                {row.value}
                                                {row.action && (
                                                    <span style={{marginLeft: '10px'}}>
                            {row.action}
                          </span>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
                <CreateFileForm accountId={accountID} setRefreshCounter={setRefreshCounter}/>
            </div>
            <div className="api-files-header">
                <h2>File Details</h2>
                <button onClick={handleRefresh} className="refresh-button">
                    <RefreshIcon/>
                </button>
            </div>
            {isLoading ? (
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px'}}>
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    {files && files.length > 0 && (
                        <div style={{height: 400, width: '100%'}}>
                            <DataGrid
                                rows={files}
                                columns={columns}
                                getRowId={(row) => row.request_time}
                                key={files.length}
                                initialState={{
                                    sorting: {
                                        sortModel: [{field: 'file_creation_time', sort: 'asc'}],
                                    },
                                }}
                            />
                        </div>
                    )}
                    {showEmptyError && accountID && (
                        <div className='api-response-text error'>No files found for this account</div>
                    )}
                </>
            )}
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this file?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ClientFileTable;