import React, {useEffect, useState} from 'react';
import {Box, IconButton, Paper} from '@mui/material';
import RecurrenceSentence from "./CronExpr";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const RecurrenceManager = ({ onSentencesChange }) => {
    const [sentences, setSentences] = useState([
        {
            frequency: 'day',
            hours: 0,
            minutes: 0,
            pastHours: 24,
            pastMinutes: 0
        }
    ]);

    useEffect(() => {
        onSentencesChange(sentences);
    }, [sentences, onSentencesChange]);

    const addSentence = () => {
        if (sentences[0].frequency === 'day') {
            const lastSentence = sentences[sentences.length - 1];
            let newHours = (lastSentence.hours + 1) % 24;
            let newSentences = [...sentences, {
                frequency: 'day',
                hours: newHours,
                minutes: 0,
                pastHours: 24,
                pastMinutes: 0
            }]
            const sortedSentences = [...newSentences].sort((a, b) => {
                if (a.hours === b.hours) {
                    return a.minutes - b.minutes;
                }
                return a.hours - b.hours;
            });


            let updatedSentences = []
            for (let i = 0; i < sortedSentences.length; i++) {
                const sentence = sortedSentences[i];
                const lastSentence = sortedSentences[(sortedSentences.length + i - 1) % sortedSentences.length];
                const hourDiff = (sentence.hours - lastSentence.hours + 24) % 24;
                const minDiff = (sentence.minutes - lastSentence.minutes + 60) % 60;
                const totalMinutes = hourDiff * 60 + minDiff;

                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                updatedSentences = [...updatedSentences, {...sentence, pastHours: hours, pastMinutes: minutes}];
            }
            setSentences(updatedSentences);
        }
    };

    const updateSentence = (index, newData) => {
        // case when switching from day to hour
        if (index === 0 && newData.frequency && newData.frequency === 'hour' && sentences[0].frequency === 'day') {
            setSentences([{frequency: 'hour', hours: 0, minutes: 0, pastHours: 0, pastMinutes: 60}]);
            return;
        }

        // case when changing hours or minutes of day, recalculate the delta
        if (sentences[index].frequency === 'day' && (newData.hours !== undefined || newData.minutes !== undefined) &&
            (sentences[index].hours !== newData.hours || sentences[index].minutes !== newData.minutes)) {
            console.log('updateSentence inside condition 2', index, newData);
            const newSentences = sentences.map((sentence, i) => {
                if (i === index) {
                    return {...sentence, ...newData};
                }
                return sentence;
            });
            const sortedSentences = [...newSentences].sort((a, b) => {
                if (a.hours === b.hours) {
                    return a.minutes - b.minutes;
                }
                return a.hours - b.hours;
            });

            // delete the sentences with same hours and minutes
            const dedupedSentences = sortedSentences.filter((sentence, i) => {
                if (i === 0) {
                    return true;
                }
                const prevSentence = sortedSentences[i - 1];
                return prevSentence.hours !== sentence.hours || prevSentence.minutes !== sentence.minutes;
            });

            // dedupedSentence is only one then make the past hours = 24 and past minutes = 0
            if (dedupedSentences.length === 1) {
                setSentences([{...dedupedSentences[0], pastHours: 24, pastMinutes: 0}]);
                return;
            }
            let updatedSentences = []
            for (let i = 0; i < dedupedSentences.length; i++) {
                const sentence = dedupedSentences[i];
                const lastSentence = dedupedSentences[(dedupedSentences.length + i - 1) % dedupedSentences.length];
                const hourDiff = (sentence.hours - lastSentence.hours + 24) % 24;
                const minDiff = (sentence.minutes - lastSentence.minutes + 60) % 60;
                const totalMinutes = hourDiff * 60 + minDiff;

                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                updatedSentences = [...updatedSentences, {...sentence, pastHours: hours, pastMinutes: minutes}];
            }
            setSentences(updatedSentences);
            return;
        }

        console.log('updateSentence', index, newData);

        // normally update the sentence
        const updatedSentences = sentences.map((sentence, i) => {
            if (i === index) {
                return {...sentence, ...newData};
            }
            return sentence;
        });
        console.log('updateSentence', index, newData, updatedSentences);
        setSentences(updatedSentences);
    };

    const deleteSentence = (indexToDelete) => {
        // Only allow deletion if it's not the first sentence
        if (indexToDelete > 0) {
            const newSentences = sentences.filter((_, index) => index !== indexToDelete);
            if (newSentences.length === 1) {
                setSentences([{...newSentences[0], pastHours: 24, pastMinutes: 0}]);
                return;
            }
            const sortedSentences = [...newSentences].sort((a, b) => {
                if (a.hours === b.hours) {
                    return a.minutes - b.minutes;
                }
                return a.hours - b.hours;
            });


            let updatedSentences = []
            for (let i = 0; i < sortedSentences.length; i++) {
                const sentence = sortedSentences[i];
                const lastSentence = sortedSentences[(sortedSentences.length + i - 1) % sortedSentences.length];
                const hourDiff = (sentence.hours - lastSentence.hours + 24) % 24;
                const minDiff = (sentence.minutes - lastSentence.minutes + 60) % 60;
                const totalMinutes = hourDiff * 60 + minDiff;

                const hours = Math.floor(totalMinutes / 60);
                const minutes = totalMinutes % 60;

                updatedSentences = [...updatedSentences, {...sentence, pastHours: hours, pastMinutes: minutes}];
            }
            setSentences(updatedSentences);
        }
    };

    const handleFrequencyChange = (index, frequency) => {
        if (frequency === 'hour') {
            setSentences([{frequency: 'hour', hours: 0, minutes: 0, pastHours: 0, pastMinutes: 60}]);
        } else {
            updateSentence(index, {frequency: frequency});
        }
    };

    return (
        <Box sx={{
            maxWidth: 600,
            padding: 2,
            backgroundColor: 'rgba(240,240,240,0.46)',
            borderRadius: '6px',
            marginBottom: '16px',
            position: 'relative',
        }}>
            {sentences.map((sentence, index) => (
                <Paper
                    key={index}
                    elevation={0}
                    sx={{
                        marginBottom: 2,
                        padding: 2,
                        backgroundColor: '#e6e3ff',
                        borderRadius: '18px',
                        maxWidth: '80%',
                        alignSelf: index % 2 === 0 ? 'flex-start' : 'flex-end',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <RecurrenceSentence
                        index={index}
                        sentence={sentence}
                        updateSentence={updateSentence}
                        onFrequencyChange={(frequency) => handleFrequencyChange(index, frequency)}
                    />
                    {index > 0 && (
                        <IconButton
                            size="small"
                            onClick={() => deleteSentence(index)}
                            sx={{
                                color: '#6a5acd',
                                width: '30px', // Set width for square shape
                                height: '30px', // Set height for square shape
                                borderRadius: '4px', // Optional for slightly rounded
                            }}
                        >
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    )}
                </Paper>
            ))}
            {sentences[0].frequency === 'day' && (
                <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 2}}>
                    <IconButton
                        onClick={addSentence}
                        sx={{
                            position: 'absolute',
                            right: '70px',
                            bottom: '45px',
                            backgroundColor: '#6a5acd',
                            color: 'white',
                            width: '30px',
                            height: '30px',
                            '&:hover': {
                                backgroundColor: '#5a4abf',
                            },
                        }}
                    >
                        <AddIcon/>
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default RecurrenceManager;