import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CompanyIcon from '../../assets/images/Earnings Intel PNG.png'; // Replace with your actual icon path

const BannerContainer = styled(Box)(({ theme }) => ({
    // backgroundColor: '#6a5acd',
    color: '#6a5acd',
    padding: '20px 0 0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Logo = styled('img')({
    height: '30px', // Adjust height as needed
    marginRight: '16px',
});

// const BannerTitle = styled(Typography)({
//     fontSize: '24px',
//     fontWeight: 'bold',
// });

const Banner = () => {
    return (
        <BannerContainer>
            <Box display="flex" alignItems="center" justifyItems={"center"} justifyContent={"center"}>
                <Logo src={CompanyIcon} alt="Company Logo" />
                {/*<BannerTitle>AE Portal</BannerTitle>*/}
            </Box>
        </BannerContainer>
    );
};

export default Banner;