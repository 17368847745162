import React, {useState, useEffect} from 'react';
import {getTopN} from '../../api';
import {useSelector} from 'react-redux';
import {selectFilters, selectFilterTags, selectKeywordList, selectUserPortfolios} from '../../reducers/dashboardSlice';
import {getSourcesFromEntitlements, getUniqueValues, isArrayNotEmpty} from '../../utils';
import {useDispatch} from 'react-redux';
import TableWrapper from '../common/TableWrapper';
import {useNavigate} from 'react-router-dom';

function TopTables({type}) {
    const filter = useSelector(selectFilters);
    const filterTags = useSelector(selectFilterTags);
    const userPortfolios = useSelector(selectUserPortfolios);
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const keywords = useSelector(selectKeywordList);

    const handleTickerClick = (ticker) => {
        navigate('/ticker/' + ticker);
    }

    const preprocessing = (data) => {
        const topPositiveSentimentData = data['S2'] ? data['S2'] : null;
        const topNegativeSentimentData = data['S3'] ? data['S3'] : null;

        return {topPositiveSentimentData, topNegativeSentimentData};
    }

    useEffect(() => {
        async function fetchData() {
            if (!userPortfolios) return;
            try {
                let u = userPortfolios.filter(p => p.id === filter.portfolioId);
                if (u.length === 0) {
                    u = userPortfolios
                }
                let tickers = getUniqueValues(u[0].symbols);
                let stored_filter_tickers = JSON.parse(window.sessionStorage.getItem('filterTickers'));
                if (isArrayNotEmpty(filterTags) && isArrayNotEmpty(stored_filter_tickers)) {
                    tickers = stored_filter_tickers
                }
                const isFilterTags = isArrayNotEmpty(filterTags);
                const requestBody = {
                    'start_date': filter.startDate,
                    'end_date': filter.endDate,
                    'stocks': tickers,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    sentiment_change: filter.mode,
                    type: isFilterTags ? type : 'event',
                    values: isFilterTags ? filterTags : ['ALEX'],
                    'is_keyword_search': filterTags.length === 1 && !keywords.includes(filterTags[0])
                }
                const response = await getTopN(requestBody);
                if (response.result != null) {
                    var d = preprocessing(response.result);
                    setData(d);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [filter, filterTags, userPortfolios]);

    return (
        <div className='flex justify-between'>
            {data.topPositiveSentimentData && (<div className='top-table-wrapper p-1 flex'>
                <TableWrapper chartData={data.topPositiveSentimentData} titleData='Top Positive Companies'
                              orderByProp='sentiment' orderProp='desc' handleTickerClick={handleTickerClick}/>
            </div>)}
            {data.topNegativeSentimentData && (<div className='top-table-wrapper p-1 flex ml-2'>
                <TableWrapper chartData={data.topNegativeSentimentData} titleData='Top Negative Companies'
                              orderByProp='sentiment' orderProp='asc' handleTickerClick={handleTickerClick}/>
            </div>)}
        </div>
    );
}

export default TopTables;