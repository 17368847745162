import React from 'react';
import '../../assets/css/chart.css';
import { useSelector } from 'react-redux';
import { selectTopNData } from '../../reducers/dashboardSlice';
import TableWrapper from '../common/TableWrapper';

function TopSentimentTable() {

    const topNData = useSelector(selectTopNData);
    const topPositiveSentimentData = topNData['S2'] ? topNData['S2'] : null;
    const topNegativeSentimentData = topNData['S3'] ? topNData['S3'] : null;
    const topPosReturnData = topNData['S4'] ? topNData['S4'] : null;
    const topNegReturnData = topNData['S5'] ? topNData['S5'] : null;
    
    return (
        <div className='flex flex-col'>
            <div className='flex'>
                {topPositiveSentimentData && (<div className='top-table-wrapper'>
                    <TableWrapper chartData={topPositiveSentimentData} titleData='Top Positive Companies' orderByProp='sentiment' orderProp='desc'/>
                </div>)}
                {topNegativeSentimentData && (<div className='top-table-wrapper ml-2'>
                    <TableWrapper chartData={topNegativeSentimentData} titleData='Top Negative Companies' orderByProp='sentiment' orderProp='asc'/>
                </div>)}
            </div>
            <div className='flex'>
                {topPosReturnData && (<div className='top-table-wrapper'>
                    <TableWrapper chartData={topPosReturnData} titleData='Top Positive Performers' orderByProp='return' orderProp='desc'/>
                </div>)}
                {topNegReturnData && (<div className='top-table-wrapper ml-2'>
                    <TableWrapper chartData={topNegReturnData} titleData='Top Negative Performers' orderByProp='return' orderProp='asc'/>
                </div>)}
            </div>
        </div>
    );
}

export default TopSentimentTable;