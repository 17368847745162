import React, { useEffect, useState } from 'react';
import '../../assets/css/company-page.css';
import { getPriceSentimentData } from '../../api';
import { getDatesBetween, getSourcesFromEntitlements } from '../../utils';
import { useSelector } from 'react-redux';
import { selectFilters } from '../../reducers/dashboardSlice';
import PriceSentimentChart from './PriceSentimentChart';    
import CompEventChart from './CompEventChart';
import CompWordChart from './CompWordChart';
import CompanyHeader from './CompanyHeader';
import CompDailyReports from './CompDailyReports';
import PSVChart from "./SentimentVolumnPriceChart";

export default function CompanyCharts() {
    const filter = useSelector(selectFilters);
    const tickerName = window.location.pathname.split('/')[2];
    const [data, setData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [pieChartData, setPieChartData] = useState({});

    const findSentimentOfDay = ([pos, neg]) => {
        var overallSentiment = Math.log((pos + 1) / (neg + 1))/Math.LN10;
        if (!overallSentiment || Math.abs(overallSentiment) === Infinity) {
            overallSentiment = 0;
        }
        return overallSentiment.toFixed(2);
    }

    const preprocessing = (data) => {
        var dates = getDatesBetween(filter.startDate, filter.endDate);
        
        var sentiments = []
        var prices = []
        var posCount = 0
        var negCount = 0
        var neutCount = 0

        data.price_data.forEach(obj => {
            const timestamp = obj.timestamp.split(' ')[0];
            obj.timestamp = timestamp;
        });

        data.sentiment_data.forEach(obj => {
            posCount += obj.sentiments[0];
            neutCount += obj.sentiments[1];
            negCount += obj.sentiments[2];
        })
        
        dates.forEach((date) => {
            var sentimentObj = data.sentiment_data.find((s) => {
                return s.timestamp === date
            })
            if (sentimentObj !== undefined) {
                sentiments.push(sentimentObj.sentiment_calc)
            } else {
                sentiments.push(null)
            }

            var priceObj = data.price_data.find((p) => {
                return p.timestamp === date
            })   
            if (priceObj !== undefined) {
                prices.push(priceObj.price)
            } else {
                prices.push(null)
            }
        })

        var color = []
        sentiments.forEach((sentiment) => {
            if (sentiment > 0) {
                color.push('#73BF69')
            } else if (sentiment < 0) {
                color.push('#F2495C')
            } else {
                color.push(null)
            }
        })

        var sentiments = sentiments.map((sentiment, index) => {
            return {y: sentiment, color: color[index]}
        })
        
        setPieChartData({
            sentimentVal: findSentimentOfDay([posCount, negCount]),
            posCount: posCount, neutCount: neutCount, negCount: negCount,
            positivePercentage: (posCount / (posCount + negCount) * 100).toFixed(2),
            negativePercentage: (negCount / (posCount + negCount) * 100).toFixed(2),
        })

        var dates = dates.map((date) => {
            return new Date(date + 'T00:00:00Z')
        })
        return {dates, sentiments, prices, posCount, negCount, neutCount};

    }

    useEffect(() => {
        async function fetchData() {
            if (!tickerName) {
                return;
            }
            try {
                const requestBody = {
                    'from': filter.startDate,
                    'to': filter.endDate,
                    'sources': getSourcesFromEntitlements(filter.checkedEntitlement),
                    'sentiment_change': filter.mode,
                    'tickers': [tickerName],
                    'timezone': "UTC"
                }
                const response = await getPriceSentimentData(requestBody);
                if (response.data != null && response.data.price_data != null) {
                    const d = preprocessing(response.data)
                    setData(d);
                    setPriceData(response.data.price_data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [filter]);   

    return (
        <div className="cmp-left-container">  
            <CompanyHeader pieChartData={pieChartData} priceData={priceData} />
            <div className='cmp-chart-container'>
                {/*<PriceSentimentChart chartData={data} />*/}
                <PSVChart />
                <CompEventChart tickerName={tickerName}/>
                <CompWordChart tickerName={tickerName}/>
                {/*<CompDailyReports tickerName={tickerName} />*/}
            </div>
        </div>
    );
}
