import React from "react";
import {TextField} from "@mui/material";
import {styled} from "@mui/material/styles";

// Custom styled components
const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#e0e0e0',
        },
        '&:hover fieldset': {
            borderColor: '#6a5acd',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6a5acd',
        },
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(0, 0, 0, 0.6)',
        '&.Mui-focused': {
            color: '#6a5acd',
        }
    },
    marginBottom: '16px'
});

const CustomTextField = ({label, value, onChange, name, required = false, type = 'text', width=200, ...props}) => {
    return (
        <StyledTextField
            fullWidth
            name={name}
            label={label}
            value={value}
            onChange={onChange}
            InputLabelProps={{shrink: true}}
            required={required}
            type={type}
            sx={{ width: width }}
            {...props}
        />
    );
}

export default CustomTextField;