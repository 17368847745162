import React from 'react';
import {Autocomplete, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';

const StyledAutocomplete = styled(Autocomplete)(({theme}) => ({
    '& .MuiInputBase-root': {
        height: '40px',
    },
    '& .MuiAutocomplete-inputRoot': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    '& .MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 14px)',
    },
    '& .MuiAutocomplete-tag': {
        margin: '2px',
    },
    '& .MuiChip-root': {
        height: '24px',
        '& .MuiChip-label': {
            paddingTop: '0',
            paddingBottom: '0',
        },
    }
}));

const CustomSingleAutocomplete = ({
                                      options,
                                      onChange,
                                      label,
                                      value,
                                      freeSolo = true,
                                      width = 300,
                                      getOptionLabel,
                                      ...props
                                  }) => {

    const defaultGetOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option;
        }
        // If option is an object, you might want to return a specific property
        // Adjust this according to your options structure
        return option.label || '';
    };

    return (
        <StyledAutocomplete
            size="medium"
            freeSolo={freeSolo}
            options={options}
            value={value}
            onChange={onChange}
            clearOnBlur
            disableClearable // This removes the X button
            sx={{width: width, margin: '0 8px 0 0'}}
            getOptionLabel={getOptionLabel || defaultGetOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    sx={{
                        input: {color: 'primary', height: '7px', fontSize: '12px'}
                    }}
                    InputLabelProps={{
                        shrink: true,
                        sx: {
                            position: 'absolute',
                            top: '0px',
                            backgroundColor: 'white',
                            // fontSize: '12px',
                            // padding: '0 4px',
                        }
                    }}
                />
            )}
            {...props}
        />
    );
};

export default CustomSingleAutocomplete;